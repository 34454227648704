<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SUBJECT_RESULT_DETAILS"
      tooltip-title="SUBJECT_RESULT_DETAILS"
      :display-breadcrumb="true"
    />
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <DetailCard :details="resultDetails" custom-classes="flex-col" />

      <div>
        <Heading heading="Students" heading-size="pb-0 text-xl" />
        <div v-if="isEmpty(examList)">
          <NoRecordFound class="mt-28" />
        </div>
        <TableWrapper v-else>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(result, index) in examList" :key="index">
              <TD>
                {{
                  result.subject_exam_schedule.exam_schedule.title ||
                  GENERAL_CONSTANTS.NOT_APPLICABLE
                }}
              </TD>
              <TD>
                {{ result.weightage }}
              </TD>
              <TD>
                <TableAction
                  :idx="index"
                  :current-user="result"
                  :action-list="actionList"
                  @action="typeAction"
                />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
        <Pagination
          v-if="resultCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
          v-show="!isLoading"
          :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
          :max-range="paginationCounts(resultCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
          @filterRecord="filteredRecords"
        />
      </div>
    </div>
    <UpdateResultModal
      v-if="isModalView"
      :modal="isModalView"
      :result="currentResult"
      @toggle="toggleUpdateExamWeightageModal"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Heading from '@components/Heading.vue'
import UpdateResultModal from '@src/router/views/exam-planner/generate-subject-exam-result/UpdateWeightageModal.vue'
import { toPascalCase } from '@utils/generalUtil'
import TableAction from '@components/TableAction.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import isEmpty from 'lodash/isEmpty'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
import DetailCard from '@/src/components/UiElements/DetailCard.vue'

export default {
  components: {
    TitleWrapper,
    Loader,
    Heading,
    UpdateResultModal,
    TableAction,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    DetailCard,
  },

  mixins: [scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      tableHeaders: ['Exam', 'Weightage', 'Actions'],
      isLoading: false,
      isModalView: false,
      actionList: [{ name: 'Edit' }],
      resultDetails: {},
      currentSelectedSubject: null,
      resultCounts: 0,
      examList: [],
    }
  },
  page: {
    title: 'Exam Subject Result',
  },
  computed: {
    ...mapState('layout', ['currentClassScope', 'currentSectionScope']),
    currentResultId() {
      return this.$route.params.id
    },
  },
  watch: {
    currentClassScope: {
      handler() {
        this.$router.push({ name: 'section-result' })
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.$router.push({ name: 'section-result' })
      },
      deep: true,
    },
  },
  mounted() {
    this.filteredRecords()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    paginationCounts,
    toPascalCase,
    toggleUpdateExamWeightageModal(payload) {
      this.isModalView = !this.isModalView
      if (!this.isModalView) this.currentResult = null
      if (payload) this.filteredRecords()
    },
    async filteredRecords(range) {
      this.isLoading = true
      let [response, error] = await this.getSubjectExamDetails(this.currentResultId)
      this.resultDetails = this.getDetails(response.data)
      this.examList = response.data.subject_exam_schedule_weightages
      this.isLoading = false
    },
    getDetails(details) {
      return {
        title: details.title,
        gradingStructure: details.non_gpa_grading.title,
        subject: details.subject.title,
        passingPercentage: details.passing_percentage,
        totalMarks: details.total_marks,
      }
    },
    typeAction(action, currentType, idx) {
      this.currentResult = currentType
      switch (action) {
        case 'Edit':
          this.toggleUpdateExamWeightageModal()
          break
      }
    },
    setRightbarData() {
      const rightBarContent = {
        header: {
          title: 'SUBJECT_RESULT_DETAILS',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'Total_RESULT', value: this.resultCounts }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('exams', ['getSubjectExamDetails']),
  },
}
</script>
